import React from "react";
import { Link } from "gatsby";

const FeaturedArticle = ({ title, image, excerpt, slug }) => {
  return (
    <div className="featured-article">
      <Link to={slug}>
        <img src={image} alt={title} className="featured-article-image" />
      </Link>
      <span className="title link">
        <Link to={slug}>{title}</Link>
      </span>
      <p>{excerpt}</p>
    </div>
  );
};

export default FeaturedArticle;
