import React, { useRef } from "react";
import FadeLeft from "../animation/FadeLeft";
import logoVideo from "../../videos/LogoAnimation1080x1080.mp4";
import { Link } from "gatsby";

const LandingPageBanner = () => {
  const videoRef = useRef(null);

  const replayVideo = () => {
    videoRef.current.pause();
    videoRef.current.currentTime = "0";
    videoRef.current.play();
  };

  return (
    <div className="home-banner padded">
      <div className="text">
        <FadeLeft delay={0}>
          <p className="subtitle">Promoting the very best in design</p>
          <p className="title">
            A platform for some of the most influential design work from around
            the globe.
          </p>
          <Link to="/submit" className="splash-link underline-slide">
            Submit your work
            <div className={"underline"} />
          </Link>
        </FadeLeft>
      </div>
      <div className="video-wrapper">
        <video
          title="Design Burger"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          className="responsive-video"
          autoPlay={true}
          muted={true}
          preload="auto"
          ref={videoRef}
          onClick={replayVideo}
        >
          <source src={logoVideo} type="video/webm" />
        </video>
      </div>
    </div>
  );
};

export default LandingPageBanner;
