import React, { useEffect, useRef, useState } from "react";
import FeaturedArticle from "./featuredArticle";
import ArrowLeft from "../../../images/svg/arrow-left.svg";
import ArrowRight from "../../../images/svg/arrow-right.svg";
import useWindowDimensions from "../../hooks/use-window-dimensions";

export default function Slider({ featuredArticles }) {
  const slidesRef = useRef(null);
  const [scrollAmount, setScrollAmount] = useState(null);
  const [imageHeight, setImageHeight] = useState(0);
  const { windowWidth } = useWindowDimensions();

  useEffect(() => {
    if (typeof window !== "undefined") {
      setImageHeight(
        document.getElementsByClassName("featured-article-image")[0]
          .offsetHeight
      );
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setScrollAmount(
        document.querySelector(".featured-article").offsetWidth + 16
      );
    }
  }, []);

  useEffect(() => {
    function handleResize() {
      if (typeof window !== "undefined") {
        setScrollAmount(
          document.querySelector(".featured-article").offsetWidth + 16
        );
        slidesRef.current.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        setImageHeight(
          document.getElementsByClassName("featured-article-image")[0]
            .offsetHeight
        );
      }
    }
    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollLeft = () => {
    if (
      slidesRef.current &&
      slidesRef.current.scrollLeft % scrollAmount === 0
    ) {
      let currentScollAmount = slidesRef.current.scrollLeft;
      let maxScrollLeft =
        slidesRef.current.scrollWidth - slidesRef.current.clientWidth;

      if (currentScollAmount + scrollAmount <= maxScrollLeft) {
        slidesRef.current.scrollBy({
          top: 0,
          left: scrollAmount,
          behavior: "smooth",
        });
      }
    }
  };

  const scrollRight = () => {
    if (
      slidesRef.current &&
      slidesRef.current.scrollLeft % scrollAmount === 0
    ) {
      slidesRef.current.scrollBy({
        top: 0,
        left: -scrollAmount,
        behavior: "smooth",
      });
    }
  };

  function shorten(str, maxLen, separator = " ") {
    if (str.length <= maxLen) return str;
    return str.substr(0, str.lastIndexOf(separator, maxLen)) + "...";
  }

  const isMobileDevice = () => {
    if (typeof navigator !== "undefined") {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    }

    return false;
  };

  return (
    <div className="slider">
      {isMobileDevice() ? null : (
        <button
          className="button back"
          onClick={scrollRight}
          style={{ top: imageHeight / 2 + "px" }}
        >
          <ArrowLeft />
        </button>
      )}
      <div className="slides" ref={slidesRef}>
        {featuredArticles.map((article, i) => (
          <FeaturedArticle
            key={i}
            title={article.frontmatter.title}
            image={article.frontmatter.featuredImages[0]}
            excerpt={
              windowWidth > 767 ? article.excerpt : shorten(article.excerpt, 50)
            }
            slug={article.fields.slug}
          />
        ))}
      </div>
      {isMobileDevice() ? null : (
        <button
          className="button forward"
          onClick={scrollLeft}
          style={{ top: imageHeight / 2 + "px" }}
        >
          <ArrowRight />
        </button>
      )}
    </div>
  );
}
