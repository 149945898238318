import * as React from "react";
import Layout from "../components/global/layout/layout";
import Seo from "../components/global/seo/seo";
import LandingPageBanner from "../components/banners/landing-page-banner";
import FeaturedArticles from "../components/carousels/featured-article-carousel/featuredArticles";
import InstagramFeed from "../components/instagram-feed/instagramFeed";
import Divider from "../components/ui/dividers/divider";

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Home" />
      <LandingPageBanner />
      <FeaturedArticles />
      <Divider padded={true} pullTop={true} />
      <InstagramFeed />
    </Layout>
  );
};

export default IndexPage;
