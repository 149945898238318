import React from "react";
import Slider from "./slider";
import { useStaticQuery, graphql } from "gatsby";

const FeaturedArticles = () => {
  const data = useStaticQuery(graphql`
    query FeaturedArticlesQuery {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        nodes {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            subtitle
            date(formatString: "MMMM DD YYYY")
            featuredImages
            category
          }
        }
      }
    }
  `);

  return (
    <div className="featured-articles">
      <span className="title padded">Featured</span>
      <Slider featuredArticles={data.allMarkdownRemark.nodes} />
    </div>
  );
};

export default FeaturedArticles;
