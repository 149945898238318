import React from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

export default function FadeLeft(props) {
  const { children, delay } = props;

  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "-50px 0px",
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x: "100px" }}
      animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : "100px" }}
      transition={{ ease: "easeOut", duration: 2, delay: delay }}
    >
      {children}
    </motion.div>
  );
}
