import React from "react";
import { globalHistory } from "@reach/router";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  createInfiniteHitsSessionStorageCache,
} from "react-instantsearch-dom";
import InfiniteHits from "./infiniteHits";

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_ONLY_API_KEY
);

const indexes = {
  ASCENDING: "designburger_date_asc",
  DECENDING: "designburger_date_desc",
};

const placeholders = [
  "Chair...",
  "Keyboard...",
  "Coffee...",
  "Headphones...",
  "Phone...",
  "Camera...",
];

const Feed = () => {
  const indexName = indexes.DECENDING;
  const sessionStorageCache = createInfiniteHitsSessionStorageCache();

  let urlQuery = globalHistory.location.search
    ? globalHistory.location.search.replace("?query=", "")
    : "";

  const updateQuery = (query) => {
    if (typeof window !== "undefined") {
      if (query === "") {
        window.history.replaceState(null, null, window.location.pathname);
      } else {
        window.history.replaceState(null, null, `?query=${query}`);
      }
    }
  };

  const userSearch = (event) => {
    updateQuery(event.currentTarget.value);
  };

  return (
    <InstantSearch searchClient={searchClient} indexName={indexName}>
      <div className="instagram-feed padded">
        <div className="search-controls">
          <div className="search-box">
            <div className="filter title link">Search</div>
            <SearchBox
              translations={{
                placeholder:
                  placeholders[Math.floor(Math.random() * placeholders.length)],
              }}
              onChange={(event) => userSearch(event)}
              onReset={(event) => updateQuery("")}
              defaultRefinement={urlQuery}
            />
          </div>
        </div>

        <InfiniteHits
          // minHitsPerPage={16}
          cache={sessionStorageCache}
        />
      </div>
    </InstantSearch>
  );
};

export default Feed;
